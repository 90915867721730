<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="spell-check" /> Prefixes List
                    </CCardHeader>            
                    <CCardBody>
                        <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                            <br>
                            <CTab>
                                <template slot="title">
                                    Active Prefix/es <CBadge color="success">( {{ [...new Set(active_sources.map((item) => item.prefix))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="active_sources"
                                            :fields="prefix_lists_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                        <template slot="no-items-view" v-if="this.table_loading">
                                            <center>
                                                <CSpinner
                                                    style="width:4rem;height:4rem;"
                                                    color="info"
                                                />
                                            </center>
                                        </template>
                                        <template #created_by="{item}">
                                            <td>
                                                {{ item.user.first_name }}
                                            </td>
                                        </template>
                                        <template #created_at="{item}">
                                                <td>
                                                        {{  `${changeDateFormatV2(item.created_at)}` }}
                                                </td>
                                            </template>   
                                        <template #action="{item}">
                                                <td>
                                                    <router-link :to="{path: `/${$store.getters['getOrganizationName']}/form-management/prefix/${item.id}`}">
                                                        <CButton 
                                                            color="info" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="cog"/> Manage
                                                        </CButton>
                                                    </router-link>
                                                    <!-- &nbsp;
                                                    <CButton 
                                                            color="danger" 
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="archive(item)"
                                                        >
                                                            <font-awesome-icon icon="trash"/> Archive
                                                        </CButton> -->
                                                </td>
                                        </template> 
                                        </CDataTable>
                                    </CCol> 
                                </CRow>
                            </CTab>  
                            <CTab>
                                <template slot="title">
                                    Inactive Prefix/es <CBadge color="danger">( {{ [...new Set(inactive_sources.map((item) => item.prefix))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="inactive_sources"
                                            :fields="prefix_lists_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                        <template #status="{item}">
                                            <td>
                                                <CBadge :color="getColorStatus(item.status)">
                                                {{ item.status }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #created_by="{item}">
                                            <td>
                                                {{ item.user.first_name }}
                                            </td>
                                        </template>
                                        <template #updated_at="{item}">
                                            <td>
                                                <small>{{  `${changeDateFormat(item.updated_at)}` }}</small>
                                            </td>
                                        </template>
                                        <template #action="{item}">
                                            <td >
                                                <template v-if="$store.getters['can']('restore-prefix')">
                                                    <CButton 
                                                        size="sm"
                                                        color="success" 
                                                        :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                        @click="restore(item)"
                                                    >

                                                    <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                                :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                </CButton>
                                                </template>
                                                <template v-else>
                                                   <i><b>No permission to restore.</b></i>
                                                </template>
                                            </td>
                                        </template>    
                                        </CDataTable>
                                    </CCol> 
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>        
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
export default {
    name:'PrefixLists',
    data(){ 
        return {
            table_loading: true,
            prefix_lists: [], 
            restoring: false,
            restoring_id: null,
            my_tab: 0,
        }
    },
    created() {
        this.$emit('activeTab', 0);
        this.getSources();
    },
    computed: {
        prefix_lists_fields: function () {
            let custom_fields= [
                { key: 'prefix', label: 'Name' },
                { key: 'created_by' },
            ]

            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Added' })
            }

            if(this.$store.getters.can('view-prefix')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },

        active_sources: function () {
            return this.prefix_lists.filter((item) => item.deleted_at == null);
        },

        inactive_sources: function () {
            return this.prefix_lists.filter((item) => item.deleted_at != null);
        }
    },
    methods: {
        changeTab: function (item) {
            this.my_tab = item
        },      
        getSources: function () {
            this.$Progress.start()
            axios.get('/form-managements/prefix/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.prefix_lists = response.data.data
                    this.table_loading = false;
                    this.$Progress.finish()    
                }
            })
        },
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/form-managements/prefix/restore/'+ item.id, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200 ) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.prefix} has been restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.prefix_lists.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                    this.restoring = false;
                    this.restoring_id = null
                }
            })
        }, 
        archive: function (item) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive the <b>${item.prefix}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/prefix/archive/' + item.id, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${item.prefix}</b> successfully archived.`,
                            })
                            
                            let date = new Date();
                            this.prefix_lists.filter(obj => obj.id === item.id)[0].deleted_at = date.toISOString();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        }
    }
}
</script>

<style>

</style>